import * as Constants from './constants'
import * as Data from './data'
import * as Forms from '@rushplay/forms'
import * as Herz from '@rushplay/herz'
import * as Icons from './icons'
import * as Payments from './payments'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ThemeUi from 'theme-ui'

import PropTypes from 'prop-types'

/**
 * Component to render when a transaction was failed
 * @component Failure
 * @param {Object} props Components props
 * @param {func} props.onStepChange callback to change payment step
 * @returns {ReactNode} component that shows why it failed and cta to restart
 */
export function Failure(props) {
  const form = Forms.useFormContext()
  const dispatch = ReactRedux.useDispatch()
  const forwardData = ReactRedux.useSelector(Data.getForwardData)
  const transactionError = ReactRedux.useSelector((state) =>
    Payments.getTransactionError(state.payments)
  )

  const translationErrorKey = `errors.${transactionError}`
  const translate = Herz.I18n.useTranslate(() => [translationErrorKey], [
    transactionError,
  ])

  React.useEffect(() => {
    dispatch(Data.transactionFailure(forwardData))
  }, [dispatch, forwardData])

  function handleStartOver() {
    // When a transaction fails and we want to restart we need to reset payments redux-state
    dispatch(Payments.resetTransaction())
    // We clear the form to have the user start over with their selections
    // and correct any potential errors
    form.destroyFields({
      exclude: ['#/properties/amount'],
    })
    props.onStepChange(Constants.TransactionStep.PaymentMethod)
  }

  return (
    <ThemeUi.Grid
      sx={{
        pt: 2,
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: 1,
        color: 'text',
        gridGap: 1,
      }}
    >
      <ThemeUi.Box
        sx={{
          color: 'danger',
          fontSize: '120px',
          pb: 2,
        }}
      >
        <Icons.Error />
      </ThemeUi.Box>
      <ThemeUi.Text>
        {/* If the error is untranslated show the generic error */}
        {translate(translationErrorKey) || translate('failure.generic-error')}
      </ThemeUi.Text>
      <ThemeUi.Flex sx={{justifyContent: 'center'}}>
        <ThemeUi.Button onClick={handleStartOver}>
          {translate('failure.cta')}
        </ThemeUi.Button>
      </ThemeUi.Flex>
    </ThemeUi.Grid>
  )
}

Failure.propTypes = {
  onStepChange: PropTypes.func.isRequired,
}

Herz.I18n.Loader.preload(['failure.generic-error', 'failure.cta'], Failure)
