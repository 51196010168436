import * as Forms from '@rushplay/forms'
import * as Payments from './payments'
import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'

import {BriteProcessing} from './brite-processing'
import {KycProcessing} from './kyc-processing'
import {NodapayProcessing} from './nodapay-processing'
import {PiqProcessing} from './piq-processing'
import {PraxisProcessing} from './praxis-processing'
import {ProjsProcessing} from './projs-processing'
import PropTypes from 'prop-types'
import {PwclickProcessing} from './pwclick-processing'
import {TransferWorldProcessing} from './transfer-world-processing'

/**
 * Helper that extracts fields with data that needs to be encrypted before sending
 * @param {Object} data All form controls
 * @returns {Object} All form controls with values encrypted for those who needed it
 */
function encryptFields(data) {
  return R.reduce(
    (acc, key) => {
      if (R.startsWith('enc', key)) {
        return R.assoc(key, window.encryptData(acc[key]), acc)
      }
      return acc
    },
    data,
    R.keys(data)
  )
}

/**
 * Collective wrapper that points data into correct component for processing
 * @param {Object} props Component props
 * @param {func} props.onStepChange callback to change payment step
 * @returns {ReactNode} Component that can process data of users selections/inputs
 */
export function TransactionProcessing(props) {
  const {transactionType, onPerform} = Payments.useTransactionContext()
  const form = Forms.useFormContext()
  const data = Forms.getFormData(form.state)
  const container = ReactRedux.useSelector((state) =>
    R.path(['activeTransaction', 'redirectOutput', 'container'], state.payments)
  )

  React.useEffect(() => {
    if (transactionType !== Payments.TransactionType.AUTH) {
      onPerform(data.provider, data.amount, encryptFields(data))
    }
  }, [data, transactionType, onPerform])

  switch (data.provider) {
    case 'PiqkycBriteAuth':
    case 'PiqkycBriteDeposit':
    case 'PiqkycTrustlyAuth':
    case 'PiqkycTrustlyDeposit': {
      return <KycProcessing onStepChange={props.onStepChange} />
    }

    case 'BriteBriteAuth':
    case 'BriteBriteDeposit':
    case 'BriteBriteWithdrawal': {
      return <BriteProcessing onStepChange={props.onStepChange} />
    }

    case 'ProjsBankidAuth':
    case 'ProjsSwishDeposit': {
      return (
        <ProjsProcessing
          onStepChange={props.onStepChange}
          orderType={
            transactionType === Payments.TransactionType.AUTH
              ? 'signin'
              : transactionType
          }
        />
      )
    }

    case 'TransferworldTransferworldWithdrawal':
    case 'TransferworldTransferworldDeposit': {
      return <TransferWorldProcessing onStepChange={props.onStepChange} />
    }

    default: {
      // The container routing defined here is kept in-case I forgot some
      // provider routing from the defined lists above
      if (container === 'trustly') {
        return <KycProcessing onStepChange={props.onStepChange} />
      }

      if (container === 'brite') {
        return <BriteProcessing onStepChange={props.onStepChange} />
      }

      if (container === 'praxis') {
        return (
          <PraxisProcessing
            onStepChange={props.onStepChange}
            orderType={transactionType}
          />
        )
      }

      if (container === 'projs') {
        return (
          <ProjsProcessing
            onStepChange={props.onStepChange}
            orderType={transactionType}
          />
        )
      }

      if (container === 'pwclick') {
        return (
          <PwclickProcessing
            onStepChange={props.onStepChange}
            orderType={transactionType}
          />
        )
      }

      if (container === 'nodapay') {
        return (
          <NodapayProcessing
            onStepChange={props.onStepChange}
            orderType={transactionType}
          />
        )
      }

      return <PiqProcessing onStepChange={props.onStepChange} />
    }
  }
}

TransactionProcessing.propTypes = {
  onStepChange: PropTypes.func.isRequired,
}
