import * as Configuration from './configuration'
import * as Constants from './constants'
import * as Data from './data'
import * as Forms from '@rushplay/forms'
import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as Reselect from 'reselect'
import * as SignUp from './sign-up'
import * as ThemeUi from 'theme-ui'

import PropTypes from 'prop-types'
import Querystring from 'querystring'

/**
 * Selector for data to process with the transaction
 * @param {Object} state Redux state
 * @returns {Object} configurations for trustly and brite
 */
const getAuthData = Reselect.createStructuredSelector({
  affiliateClickId: Data.getAffiliateClickId,
  brand: Configuration.getBrand,
  clientType: Configuration.getClientType,
  countryCode: Configuration.getCountryCode,
  currency: Configuration.getCurrency,
  seonSession: Configuration.getSeonSession,
  language: Configuration.getLanguage,
  netrefererBtag: Data.getNetrefererBtag,
  offerId: Configuration.getOfferId,
  origin: Configuration.getOrigin,
  piqConfig: Configuration.getPiqKycConfig,
  ssn: Data.getSsn,
  sessionToken: Configuration.getToken,
  utmCampaign: Data.getUtmCampaign,
  utmMedium: Data.getUtmMedium,
  utmSource: Data.getUtmSource,
  verificationToken: Data.getVerificationToken,
})

/**
 * Component to handle Trustly and Brite transactions
 * @component KycProcessing
 * @param {Object} props Components props
 * @param {func} props.onStepChange callback to change payment step
 * @returns {ReactNode} Iframe with trustly loaded & observer to post event to main app to move on onSuccess/failure
 */
export function KycProcessing(props) {
  const authData = ReactRedux.useSelector(getAuthData)
  const dispatch = ReactRedux.useDispatch()
  const form = Forms.useFormContext()
  const data = Forms.getFormData(form.state)
  const query = Querystring.parse(R.drop(1, location.search))
  const amountQuery = query['amount_cents'] / 100
  const amountForm = data.amount / 100
  const amount = amountForm || amountQuery || null

  function handleSuccess(sessionToken) {
    dispatch(Data.updateForwardingData({sessionToken}))
    props.onStepChange(Constants.TransactionStep.Success)
  }

  const handleFailure = React.useCallback(
    (error) => {
      dispatch(Data.updateForwardingData({error}))
      props.onStepChange(Constants.TransactionStep.Failure)
    },
    [props.onStepChange]
  )

  return (
    <ThemeUi.Box
      sx={{
        'width': '100%',
        'height': '100%',
        '> iframe': {
          border: 'none',
          margin: '0px',
        },
      }}
    >
      <SignUp.Iframe
        amount={amount}
        brand={authData.brand}
        btag={authData.netrefererBtag}
        clickId={authData.affiliateClickId}
        clientType={authData.clientType}
        countryCode={authData.countryCode}
        currency={authData.currency}
        depositOfferId={authData.offerId}
        seonSession={authData.seonSession}
        language={authData.language}
        host={authData.piqConfig.apiUrl}
        identityProvider={authData.piqConfig.providerType}
        clientId={authData.piqConfig.clientId}
        sessionToken={authData.sessionToken}
        ssn={authData.ssn}
        successRedirectUrl={`${authData.origin}/callbacks/${authData.piqConfig.providerType}`}
        failureRedirectUrl={`${authData.origin}/callbacks/${authData.piqConfig.providerType}?error_description=cancelled-transaction`}
        utmCampaign={authData.utmCampaign}
        utmMedium={authData.utmMedium}
        utmSource={authData.utmSource}
        verificationToken={authData.verificationToken}
        onError={handleFailure}
      />
      <SignUp.SignUpObserver
        onCancel={() => props.onStepChange(Constants.TransactionStep.Cancel)}
        onError={handleFailure}
        onSuccess={handleSuccess}
      />
    </ThemeUi.Box>
  )
}

KycProcessing.propTypes = {
  onStepChange: PropTypes.func.isRequired,
}
